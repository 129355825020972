import gql from "graphql-tag";

export default gql`
  mutation LoginWithGoogleAccessToken($accessToken: String!) {
    loginWithGoogleAccessToken(accessToken: $accessToken) {
      email
      isRecentlyCreatedUser
      accessToken
    }
  }
`;
