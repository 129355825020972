import { useMutation } from "@apollo/client";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { useEffect, useState } from "react";
import VerifyEmail from "../../lib/queries/auth/VerifyEmail";
import type {
  IVerifyEmailMutation,
  IVerifyEmailMutationVariables,
} from "../../lib/queries/auth/__generated__/VerifyEmail.generated";

export default function useEmailVerification(verificationToken: string | null, onVerify: () => void) {
  const sendDataToGTM = useGTMDispatch();
  const [emailVerified, setEmailVerified] = useState<boolean>(false);

  const [verifyEmail, { loading: isVerifying }] = useMutation<IVerifyEmailMutation, IVerifyEmailMutationVariables>(
    VerifyEmail,
    {
      ignoreResults: false,
      onCompleted: ({ verifyEmail }) => {
        if (verifyEmail) {
          sendDataToGTM({
            event: "verifiedEmail",
          });
        }
        setEmailVerified(verifyEmail || false);
        onVerify();
      },
      onError: (error) => {
        if (error && /The email is already verified/i.test(error.message)) {
          sendDataToGTM({
            event: "verifiedEmail",
          });
          setEmailVerified(true);
          onVerify();
        }
      },
    },
  );

  useEffect(() => {
    if (verificationToken) {
      verifyEmail({
        variables: {
          token: verificationToken,
        },
      });
    }
  }, [verificationToken]);

  useEffect(() => {
    let lastScheduledTimeout: NodeJS.Timeout;

    if (emailVerified) {
      lastScheduledTimeout = setTimeout(() => {
        setEmailVerified(false);
      }, 5000);
    }

    return () => {
      if (lastScheduledTimeout) {
        clearTimeout(lastScheduledTimeout);
      }
    };
  }, [emailVerified]);

  return {
    isVerifying,
    emailVerified,
  };
}
