import type { ApolloError, ServerError } from "@apollo/client";

export default function handleMessageGraphQLErrors({ graphQLErrors, networkError }: ApolloError): string | null {
  if (graphQLErrors.length > 0) {
    const [error] = graphQLErrors;
    return error.message;
  }

  if (!networkError) {
    return null;
  }

  if ((networkError as ServerError).result && (networkError as ServerError).result.errors.length > 0) {
    const [error] = (networkError as ServerError).result.errors;

    return error.message;
  }

  return networkError.message ?? null;
}
