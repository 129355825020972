/* eslint-disable react-hooks/rules-of-hooks */
import { zodResolver } from "@hookform/resolvers/zod";
import { loginCredentialsDataShape } from "components/auth/form";
import { Button } from "components/ui/Button";
import { Form, FormField, FormMessage } from "components/ui/Form";
import { Input } from "components/ui/Input";
import Link from "next/link";
import type { ReactElement } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

interface ILoginCredentialsFormValues {
  email: string;
  password: string;
}

interface ILoginCredentialsFormProps {
  isPromo?: boolean;
  onSubmit: (values: ILoginCredentialsFormValues) => void;
}

export default function LoginCredentialsForm({ isPromo, onSubmit }: ILoginCredentialsFormProps): ReactElement {
  const intl = useIntl();

  const form = useForm({
    mode: "onSubmit",
    resolver: zodResolver(loginCredentialsDataShape(intl)),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const {
    formState: { isSubmitting },
  } = form;

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <div className="flex flex-col space-y-3 text-left">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => {
              return (
                <div className="space-y-2">
                  <Input
                    {...field}
                    height={44}
                    type="email"
                    spellCheck={false}
                    autoComplete="off"
                    placeholder="Email"
                    name="email"
                    maxLength={256}
                  />

                  <FormMessage />
                </div>
              );
            }}
          />
        </div>

        <div className="flex flex-col space-y-3 text-left">
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => {
              return (
                <div className="space-y-2">
                  <Input
                    {...field}
                    height={44}
                    type="password"
                    spellCheck={false}
                    autoComplete="off"
                    placeholder={intl.formatMessage({
                      defaultMessage: "Senha",
                      id: "yUZJVn",
                    })}
                    name="password"
                    maxLength={256}
                  />

                  <FormMessage />
                </div>
              );
            }}
          />
        </div>

        {!isPromo && (
          <div className="text-right">
            <Link href="/forgot-password" className="inline-flex text-sm font-medium text-blue-600 hover:underline">
              <FormattedMessage defaultMessage="Esqueceu sua senha?" id="dFA3D7" />
            </Link>
          </div>
        )}

        <Button type="submit" variant="default" className="w-full" disabled={isSubmitting}>
          {isSubmitting ? (
            <FormattedMessage defaultMessage="Aguarde..." id="VZazm7" />
          ) : (
            <FormattedMessage defaultMessage="Logar" id="k6nBJm" />
          )}
        </Button>
      </form>
    </Form>
  );
}
