/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react-hooks/exhaustive-deps */
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation, Query } from "@apollo/client/react/components";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import Auth from "components/auth/Auth";
import Footer from "components/auth/Footer";
import GoogleOAuthButton from "components/auth/GoogleOAuthButton";
import { runWithGoogleAccountsId } from "components/auth/GoogleOneTapSignIn";
import LoginCredentialsForm from "components/auth/LoginCredentialsForm";
import useEmailVerification from "components/auth/useEmailVerification";
import FundamenteiWithText from "components/branding/FundamenteiWithText";
import LogoSizingProps from "components/branding/LogoSizingProps";
import Divider from "components/core/Divider";
import FundamenteiMetadata from "components/seo/FundamenteiMetadata";
import { getUtmParametersFromParsedUrlQuery, preserveOnlyUtmParameters } from "lib/analytics/utm";
import CheckoutUrlMutation from "lib/queries/CheckoutUrlMutation";
import type {
  ICheckoutUrlMutation,
  ICheckoutUrlMutationVariables,
} from "lib/queries/__generated__/CheckoutUrlMutation.generated";
import getQueryStringValue from "lib/utils/getQueryStringValue";
import type { NextPage } from "next";
import Link from "next/link";
import Router, { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useAsyncCallback } from "react-async-hook";
import { ICheckoutProduct } from "types/graphqlTypes";

import classNames from "components/ui/classNames";
import parseISO from "date-fns/parseISO";
import differenceInSeconds from "date-fns/differenceInSeconds";
import { useAmplitude } from "lib/amplitude/Amplitude";
import useFlashMessage from "lib/hooks/useFlashMessage";
import LoginWithCredentials from "lib/queries/auth/LoginWithCredentials";
import LoginWithGoogleOAuthAccessToken from "lib/queries/auth/LoginWithGoogleAccessToken";
import SignUpFromGoogleOAuthAccessToken from "lib/queries/auth/SignUpFromGoogleOAuthAccessToken";
import type {
  ILoginWithCredentialsMutation,
  ILoginWithCredentialsMutationVariables,
} from "lib/queries/auth/__generated__/LoginWithCredentials.generated";
import type {
  ILoginWithGoogleAccessTokenMutation,
  ILoginWithGoogleAccessTokenMutationVariables,
} from "lib/queries/auth/__generated__/LoginWithGoogleAccessToken.generated";
import type {
  ISignUpFromGoogleOAuthAccessTokenMutation,
  ISignUpFromGoogleOAuthAccessTokenMutationVariables,
} from "lib/queries/auth/__generated__/SignUpFromGoogleOAuthAccessToken.generated";
import CouponById from "lib/queries/CouponById";
import type { ICouponByIdQuery, ICouponByIdQueryVariables } from "lib/queries/__generated__/CouponById.generated";
import notUndefinedOrNull from "lib/utils/notUndefinedOrNull";
import stripUndefined from "lib/utils/stripUndefined";
import handleMessageGraphQLErrors from "components/utils/handleMessageGraphQLErrors";
import { FormattedMessage, useIntl } from "react-intl";

interface IRedeemByCountdownProps {
  redeemBy: string;
}

function RedeemByCountdown({ redeemBy }: IRedeemByCountdownProps) {
  const validUntil = parseISO(redeemBy);
  const [diffInSeconds, setDiffInSeconds] = useState(() => {
    return differenceInSeconds(validUntil, new Date());
  });
  useEffect(() => {
    const intervalId = setInterval(() => {
      setDiffInSeconds(differenceInSeconds(validUntil, new Date()));
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const days = Math.floor(diffInSeconds / 86400);
  const hours = Math.floor((diffInSeconds % 86400) / 3600);
  const minutes = Math.floor((diffInSeconds % 3600) / 60);
  const seconds = Math.floor(diffInSeconds % 60);

  const timeSlots = [
    days > 0 ? `${days}d` : null,
    hours > 0 ? `${hours}h` : null,
    minutes > 0 ? `${minutes}m` : null,
    seconds > 0 ? `${seconds}s` : null,
  ]
    .filter(notUndefinedOrNull)
    .join(" ");

  return (
    <time
      suppressHydrationWarning={true}
      dateTime={redeemBy}
      className="animate-pulse text-2xl font-semibold italic text-gray-900 duration-1000"
    >
      {timeSlots}
    </time>
  );
}

interface ILoginProps {
  checkout: string | null;
  promo: string | null;
  verificationToken: string | null;
  redirectTo: string | null;
}

const Login: NextPage<ILoginProps> = function Login({ checkout, promo, verificationToken, redirectTo }: ILoginProps) {
  const [errorMessage, setErrorMessage] = useFlashMessage(16000);

  const intl = useIntl();
  const router = useRouter();
  const amplitude = useAmplitude();
  const apolloClient = useApolloClient();
  const sendDataToGTM = useGTMDispatch();

  const [checkoutUrl] = useMutation<ICheckoutUrlMutation, ICheckoutUrlMutationVariables>(CheckoutUrlMutation, {});
  const checkoutProduct = useAsyncCallback(async (product: ICheckoutProduct) => {
    const { data } = await checkoutUrl({
      variables: {
        product,
        coupon: promo,
        parameters: null,
      },
    });

    if (data && data.checkoutUrl) {
      window.location.href = data.checkoutUrl;
    }
  });

  const { isVerifying, emailVerified } = useEmailVerification(verificationToken, () => {
    amplitude.logEvent("Verified email");
    Router.replace("/login", "/login", {});
  });
  const isPromo = promo !== null;

  return (
    <div className="mx-auto h-full min-h-screen w-screen bg-brand py-16">
      <FundamenteiMetadata title="Login | Fundamentei" />
      <div className="mx-auto flex max-w-7xl items-center justify-center text-center">
        <div className="w-full max-w-sm">
          <div className="space-y-6">
            <div className="text-center">
              <Link
                href={{
                  pathname: "/",
                  query: preserveOnlyUtmParameters(router.query),
                }}
                title="Fundamentei"
                className="flex justify-center text-center"
              >
                <FundamenteiWithText {...LogoSizingProps.FundamenteiWithText.SMALL} fill="#ffffff" />
              </Link>
            </div>

            {isPromo && (
              <Query<ICouponByIdQuery, ICouponByIdQueryVariables>
                query={CouponById}
                variables={{
                  id: promo,
                }}
              >
                {({ loading, data }) => {
                  return loading || !data?.couponById ? null : (
                    <div
                      className={classNames(
                        "space-y-4 rounded-xl border-y border-transparent bg-white p-6 font-aeonik font-normal md:border",
                        "ring-2 ring-brand/20 ring-offset-2 ring-offset-brand/5",
                      )}
                    >
                      <div className="flex items-center justify-between space-x-4">
                        <div className="space-y-2 text-left">
                          <h3 className="flex items-center space-x-2 text-xl font-bold text-gray-800">
                            <span>Desconto exclusivo!</span>
                            <span className="relative flex h-2 w-2 md:h-2 md:w-2">
                              <span className="animate-ping-slow absolute inline-flex h-full w-full animate-ping rounded-full bg-emerald-600/50 opacity-80" />
                              <span className="relative inline-flex h-2 w-2 rounded-full bg-emerald-600 md:h-2 md:w-2" />
                            </span>
                          </h3>
                          <ul className="list-inside list-decimal text-sm italic text-slate-600">
                            <li>Apenas para novas assinaturas</li>
                            <li>Durante {data.couponById.durationInMonths} meses</li>
                          </ul>
                        </div>

                        {data.couponById.__typename === "StripeFixedAmountCoupon" && (
                          <h1 className="rounded-2xl bg-slate-50 p-4 text-2xl font-bold tracking-wide text-slate-700 line-through">
                            R${data.couponById.amountOff / 100}
                          </h1>
                        )}

                        {data.couponById.__typename === "StripePercentCoupon" && (
                          <h1 className="rounded-2xl bg-slate-50 p-4 text-2xl font-bold tracking-wide text-slate-700 line-through">
                            {data.couponById.percentOff}%
                          </h1>
                        )}
                      </div>

                      <div className="space-y-1 text-left">
                        <div className="space-x-2">
                          <strong className="rounded-lg bg-orange-50 px-2 py-1 text-xs font-bold uppercase text-orange-600">
                            {data.couponById.name}
                          </strong>
                          <strong className="rounded-lg bg-violet-50 px-2 py-1 text-xs font-bold uppercase text-violet-600">
                            Oferta válida por
                          </strong>
                        </div>

                        <div>
                          <RedeemByCountdown redeemBy={data.couponById.redeemBy} />
                        </div>
                      </div>
                    </div>
                  );
                }}
              </Query>
            )}

            <div className="space-y-8 rounded-xl border-y border-transparent bg-white p-6 font-aeonik font-normal shadow-sm md:border">
              <div className="space-y-0.5 text-center">
                <h1 className="m-0 text-2xl font-bold">
                  <FormattedMessage description="Página de login" defaultMessage="Faça Login" id="PDT2Kx" />
                </h1>
                {isPromo ? (
                  <p className="text-sm font-normal tracking-wide text-slate-500">
                    <FormattedMessage
                      description="Página de login"
                      defaultMessage="...para resgatar sua promoção agora!"
                      id="j7Y9GJ"
                    />
                  </p>
                ) : (
                  <p className="text-sm font-normal tracking-wide text-slate-500">
                    <FormattedMessage
                      description="Página de login"
                      defaultMessage="...na comunidade mais completa de Buy&Hold!"
                      id="F1o0ly"
                    />
                  </p>
                )}
              </div>

              <div className="space-y-5">
                {isVerifying && (
                  <div className="-mx-6 bg-slate-100 px-6 py-3">
                    <p className="animate-pulse text-sm font-medium tracking-wide text-slate-500">
                      <FormattedMessage
                        description="Página de login"
                        defaultMessage="Aguarde...verificando seu email."
                        id="ioeQZi"
                      />
                    </p>
                  </div>
                )}

                {emailVerified && (
                  <div className="-mx-6 bg-emerald-50 px-6 py-3">
                    <p className="text-sm font-medium tracking-wide text-emerald-600">
                      <FormattedMessage
                        description="Página de login"
                        defaultMessage="Email verificado com sucesso! Faça seu login utilizando o formulário abaixo."
                        id="ZN5a+u"
                      />
                    </p>
                  </div>
                )}

                <Auth>
                  {({ login }) => (
                    <Mutation<ILoginWithCredentialsMutation, ILoginWithCredentialsMutationVariables>
                      mutation={LoginWithCredentials}
                      errorPolicy="all"
                      onCompleted={(data) => {
                        if (data && data.loginWithCredentials) {
                          login(data.loginWithCredentials.accessToken);

                          runWithGoogleAccountsId((id) => {
                            id.cancel();
                          });

                          if (
                            checkout &&
                            [
                              ICheckoutProduct.MonthlyRecurringSubscription,
                              ICheckoutProduct.YearlyRecurringSubscription,
                            ].includes(checkout as ICheckoutProduct)
                          ) {
                            checkoutProduct.execute(checkout as ICheckoutProduct);
                          } else if (redirectTo) {
                            Router.replace(redirectTo);
                          } else {
                            Router.replace("/");
                          }

                          amplitude.logEvent("Signed in", {
                            method: "credentials",
                            email: data.loginWithCredentials.email,
                            ...getUtmParametersFromParsedUrlQuery(router.query),
                          });
                        }
                      }}
                      onError={(apolloError) => {
                        const message = handleMessageGraphQLErrors(apolloError);

                        if (!message) {
                          return;
                        }

                        if (/Invalid credentials/i.test(message)) {
                          setErrorMessage(
                            intl.formatMessage({
                              defaultMessage: "Email e/ou senha inválidos.",
                              id: "HL74Mb",
                              description: "Possíveis erros ao submeter um formulário",
                            }),
                          );
                        }

                        if (/You're connected with Google without a password set/i.test(message)) {
                          setErrorMessage(
                            intl.formatMessage({
                              defaultMessage: `Atenção! Você está conectado com a conta do Google, clique em "Logar com o Google" abaixo para efetuar o login.`,
                              id: "7WOFN4",
                              description: "Possíveis erros ao submeter um formulário",
                            }),
                          );
                        }
                      }}
                    >
                      {(loginWithCredentials) => (
                        <LoginCredentialsForm
                          isPromo={isPromo}
                          onSubmit={async ({ email, password }) => {
                            await apolloClient.resetStore();
                            return loginWithCredentials({
                              variables: {
                                email,
                                password,
                              },
                            });
                          }}
                        />
                      )}
                    </Mutation>
                  )}
                </Auth>

                {errorMessage && (
                  <div className="-mx-6 bg-red-50 px-6 py-3">
                    <p className="text-sm font-medium tracking-wide text-red-600">{errorMessage}</p>
                  </div>
                )}

                <Divider>
                  <span className="text-sm font-bold text-slate-400">OU</span>
                </Divider>

                <Auth>
                  {({ login }) => (
                    <Mutation<
                      ISignUpFromGoogleOAuthAccessTokenMutation,
                      ISignUpFromGoogleOAuthAccessTokenMutationVariables
                    >
                      mutation={SignUpFromGoogleOAuthAccessToken}
                      onError={({ networkError, graphQLErrors }) => {
                        if (networkError) {
                          setErrorMessage(
                            intl.formatMessage({
                              defaultMessage: "Não foi possível se comunicar com o servidor.",
                              id: "Z1uKA7",
                              description: "Possíveis erros ao submeter um formulário",
                            }),
                          );
                          return;
                        }

                        if (graphQLErrors && graphQLErrors.length > 0) {
                          const topError = graphQLErrors[0];

                          if (/Couldn't process your sign up/i.test(topError.message)) {
                            setErrorMessage(
                              intl.formatMessage({
                                defaultMessage:
                                  "Não foi possível processar seu cadastro agora. Tente novamente mais tarde. Desculpe pela inconveniência :(",
                                id: "cu7wXp",
                                description: "Possíveis erros ao submeter um formulário",
                              }),
                            );
                            return;
                          }
                        }

                        setErrorMessage(
                          intl.formatMessage({
                            defaultMessage:
                              "Whoopz...deu algum problema aqui no servidor. Tente novamente mais tarde :(",
                            id: "58MCIn",
                            description: "Possíveis erros ao submeter um formulário",
                          }),
                        );
                      }}
                      onCompleted={(data) => {
                        if (data && data.signUpFromGoogleOAuthAccessToken) {
                          if (data.signUpFromGoogleOAuthAccessToken.isRecentlyCreatedUser) {
                            amplitude.logEvent("Signed up", {
                              authentication: "google",
                              email: data.signUpFromGoogleOAuthAccessToken.email,
                              ...getUtmParametersFromParsedUrlQuery(router.query),
                            });

                            sendDataToGTM({
                              event: "signedUp",
                            });
                          }

                          login(data.signUpFromGoogleOAuthAccessToken.accessToken);

                          runWithGoogleAccountsId((id) => {
                            id.cancel();
                          });

                          if (
                            checkout &&
                            [
                              ICheckoutProduct.MonthlyRecurringSubscription,
                              ICheckoutProduct.YearlyRecurringSubscription,
                            ].includes(checkout as ICheckoutProduct)
                          ) {
                            checkoutProduct.execute(checkout as ICheckoutProduct);
                          } else if (redirectTo) {
                            Router.replace(redirectTo);
                          } else {
                            Router.replace("/");
                          }

                          amplitude.logEvent(
                            "Signed in",
                            stripUndefined({
                              method: "google",
                              email: data.signUpFromGoogleOAuthAccessToken.email,
                              ...getUtmParametersFromParsedUrlQuery(router.query),
                              promo: isPromo ? promo : undefined,
                            }),
                          );
                        }

                        setErrorMessage("");
                      }}
                    >
                      {(signUpFromGoogleOAuthAccessToken, { loading }) => {
                        return loading ? (
                          <div className="-mx-6 bg-emerald-50 px-6 py-3">
                            <p className="text-sm font-medium tracking-wide text-emerald-600">{errorMessage}</p>
                          </div>
                        ) : (
                          <Mutation<ILoginWithGoogleAccessTokenMutation, ILoginWithGoogleAccessTokenMutationVariables>
                            mutation={LoginWithGoogleOAuthAccessToken}
                            onError={({ networkError, graphQLErrors }) => {
                              if (networkError) {
                                setErrorMessage(
                                  intl.formatMessage({
                                    defaultMessage:
                                      "Não foi possível se comunicar com o servidor. Verifique sua conexão.",
                                    id: "G2nEsQ",
                                    description: "Possíveis erros ao submeter um formulário",
                                  }),
                                );
                                return;
                              }

                              if (graphQLErrors.length > 0) {
                                const topError = graphQLErrors[0];
                                if (
                                  topError.extensions &&
                                  topError.extensions.code === "UNAUTHENTICATED" &&
                                  /User not found/i.test(topError.message)
                                ) {
                                  throw new Error("User not found");
                                }
                              }

                              setErrorMessage(
                                intl.formatMessage({
                                  defaultMessage:
                                    "Whoopz...deu algum problema aqui no servidor. Tente novamente mais tarde :(",
                                  id: "58MCIn",
                                  description: "Possíveis erros ao submeter um formulário",
                                }),
                              );
                            }}
                            onCompleted={(data) => {
                              setErrorMessage("");

                              if (data && data.loginWithGoogleAccessToken) {
                                login(data.loginWithGoogleAccessToken.accessToken);

                                runWithGoogleAccountsId((id) => {
                                  id.cancel();
                                });

                                if (
                                  checkout &&
                                  [
                                    ICheckoutProduct.MonthlyRecurringSubscription,
                                    ICheckoutProduct.YearlyRecurringSubscription,
                                  ].includes(checkout as ICheckoutProduct)
                                ) {
                                  checkoutProduct.execute(checkout as ICheckoutProduct);
                                } else if (redirectTo) {
                                  Router.replace(redirectTo);
                                } else {
                                  Router.replace("/");
                                }

                                amplitude.logEvent(
                                  "Signed in",
                                  stripUndefined({
                                    method: "google",
                                    email: data.loginWithGoogleAccessToken.email,
                                    ...getUtmParametersFromParsedUrlQuery(router.query),
                                    promo: isPromo ? promo : undefined,
                                  }),
                                );
                              }
                            }}
                          >
                            {(loginWithGoogleAccessToken) => (
                              <GoogleOAuthButton
                                onGetAccessToken={async (accessToken) => {
                                  await apolloClient.resetStore();

                                  loginWithGoogleAccessToken({
                                    variables: {
                                      accessToken,
                                    },
                                  }).catch((err?: Error) => {
                                    if (err && /User not found/.test(err.message)) {
                                      signUpFromGoogleOAuthAccessToken({
                                        variables: {
                                          accessToken,
                                          utms: {
                                            utmSource: null,
                                            utmMedium: null,
                                            utmCampaign: null,
                                            utmTerm: null,
                                            utmContent: null,
                                            ...getUtmParametersFromParsedUrlQuery(router.query),
                                          },
                                        },
                                      });

                                      return;
                                    }

                                    throw err;
                                  });
                                }}
                              >
                                <FormattedMessage defaultMessage="Logar com o Google" id="Of8vyn" />
                              </GoogleOAuthButton>
                            )}
                          </Mutation>
                        );
                      }}
                    </Mutation>
                  )}
                </Auth>

                {!isPromo && (
                  <p className="text-center text-sm text-gray-600">
                    <FormattedMessage
                      defaultMessage="Ainda não tem uma conta? <a>Cadastre-se!</a>"
                      id="I4J6EW"
                      values={{
                        a: (text) => {
                          return (
                            <Link
                              href={{
                                pathname: "/sign-up",
                                query: preserveOnlyUtmParameters(router?.query),
                              }}
                              passHref={true}
                              className="font-medium text-blue-600 hover:underline"
                            >
                              {text}
                            </Link>
                          );
                        },
                      }}
                    />
                  </p>
                )}
              </div>
            </div>

            <Footer className="font-medium text-white" />
          </div>
        </div>
      </div>
    </div>
  );
};

Login.getInitialProps = async ({ query }) => ({
  verificationToken: typeof query.verificationToken === "string" ? query.verificationToken : null,
  checkout: getQueryStringValue(query, "checkout"),
  promo: getQueryStringValue(query, "promo"),
  redirectTo: getQueryStringValue(query, "redirectTo"),
});

export default Login;
