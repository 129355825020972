import gql from "graphql-tag";

export default gql`
  query CouponById($id: ID!) {
    couponById(id: $id) {
      id
      name
      ... on StripePercentCoupon {
        percentOff
      }
      ... on StripeFixedAmountCoupon {
        amountOff
      }
      duration
      durationInMonths
      redeemBy
      valid
    }
  }
`;
